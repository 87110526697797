* {
    font-family: "Roboto", sans-serif;
}

body {
    margin: 0;
}

::-webkit-scrollbar-track {
    background: none;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-thumb {
    background: #ccc;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.pointer {
    cursor: pointer;
}

.suspenseScreen {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.suspenseScreen h1,
.suspenseScreen h3 {
    margin-bottom: 0;
}

.suspenseScreen h3 {
    margin-top: 5px;
}

.suspenseScreen .suspenseLoader {
    margin: 15px 0;
}


/** Event Modal **/
.eventModal-outline:focus{
    outline: none !important;
}